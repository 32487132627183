import styles from './LoadingSkeleton.module.scss';
import Image from 'next-export-optimize-images/image';

const LoadingSkeleton = () => {
  const baseUrl = process.env.baseUrl;

  return (
    <div className={styles.root}>
      <div className={styles.logoWrapper}>
        <Image
          src={`${baseUrl}/images/animated_logo.gif`}
          alt=''
          width={239}
          height={100}
          priority
        />
      </div>
    </div>
  );
};

export default LoadingSkeleton;
